/// Actions
const base = "todoit/outcomes/daily";
const EDIT = `${base}/edit`;
const DELETE = `${base}/delete`;
const NEW_DATE = `${base}/new_date`;
const SAVE = `${base}/save`;
const LOAD = `${base}/load`;
const SET_DOING = `${base}/set_doing`;
const SET_DONE = `${base}/set_done`;

export interface IAction {
  type: string;
  payload?: any;
}

export interface Outcome {
  text: string;
  done: boolean;
}

export interface DailyOutcomesState {
  date: string | null;
  doing: boolean;
  first: Outcome;
  second: Outcome;
  third: Outcome;
}

export const INITIAL_STATE: DailyOutcomesState = {
  date: null,
  doing: false,
  first: {
    text: "",
    done: false,
  },
  second: {
    text: "",
    done: false,
  },
  third: {
    text: "",
    done: false,
  },
};

export enum OutcomeNumber {
  First = "first",
  Second = "second",
  Third = "third",
}

/// Action Creators
/**
 * Sets a new date for the daily outcomes
 * @param date The new date to set "yyyy-MM-dd"
 */
export const setNewDate = (date: string) => ({ type: NEW_DATE, payload: date });

/**
 * This will store the new text for the given outcome
 * @param outcome Which outcome to edit (first, second or third)
 * @param text The new text
 */
export const editOutcome = (outcome: OutcomeNumber, text: string) => ({ type: EDIT, payload: { outcome, text } });

/**
 * This will mark an outcome as done or not
 * @param outcome Which outcome to set (first, second or third)
 * @param done If it should be marked as done or not
 */
export const setDone = (outcome: OutcomeNumber, done: boolean) => ({ type: SET_DONE, payload: { outcome, done } });

/**
 * This will delete all text for the given outcome
 * @param outcome Which outcome to edit (first, second or third)
 */
export const deleteOutcome = (outcome: OutcomeNumber) => ({ type: DELETE, payload: outcome });

/**
 * This will store the daily state in localStorage
 */
export const saveOutcomes = () => ({ type: SAVE });

/**
 * This will load any stored state for
 */
export const loadOutcomes = (date: string) => ({ type: LOAD, payload: date });

/**
 *
 * @param doing True to show doable items, false to edit items
 */
export const setDoing = (doing: boolean) => ({ type: SET_DOING, payload: doing });

const getText = (state: DailyOutcomesState, outcome: OutcomeNumber) => state[outcome].text;

/// Reducer
export default (state: DailyOutcomesState = INITIAL_STATE, action?: IAction) => {
  switch (action?.type) {
    case NEW_DATE:
      return { ...state, date: action.payload };

    case EDIT:
      return { ...state, [action.payload.outcome]: { text: action.payload.text, done: false } };

    case SET_DONE:
      return {
        ...state,
        [action.payload.outcome]: { done: action.payload.done, text: getText(state, action.payload.outcome) },
      };

    case DELETE:
      return { ...state, [action.payload]: { text: "", done: false } };

    case SAVE:
      localStorage.setItem(`daily_${state.date}`, JSON.stringify(state));
      return state;

    case LOAD:
      const loaded = localStorage.getItem(`daily_${action.payload}`);
      const newState = loaded ? JSON.parse(loaded) : INITIAL_STATE;
      return { ...newState };

    case SET_DOING:
      return { ...state, doing: action.payload };
  }
  return state;
};
