import { createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import outcomes, { OutcomesState, INITIAL_STATE as outcomes_state } from "./outcomes";

export interface RootState {
  outcomes: OutcomesState;
}

export const INITIAL_STATE = {
  outcomes: outcomes_state,
};

export const reducer = combineReducers({
  outcomes,
});

export default createStore(
  reducer,
  INITIAL_STATE,
  devToolsEnhancer({ name: "ToDoIt Store" }),
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
);
