import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { DailyOutcomesState, loadOutcomes } from "../../store/outcomes/daily/daily";
import NewDayView from "./views/NewDayView";
import AddOutcomesView from "./views/AddOutcomesView";
import { DoItView } from "..";

export const today = () => new Date().toISOString().split("T")[0];

function DailyOutcomes() {
  const dispatch = useDispatch();
  const outcomes = useSelector<RootState, DailyOutcomesState>((state) => state.outcomes.daily);

  useEffect(() => {
    dispatch(loadOutcomes(today()));
  }, [dispatch]);

  const SelectView = () => {
    if (outcomes.date) {
      if (outcomes.doing) return <DoItView />;
      return <AddOutcomesView />;
    }
    return <NewDayView />;
  };

  return <div>{SelectView()}</div>;
}

export default DailyOutcomes;
