import { combineReducers } from "redux";
import daily, { DailyOutcomesState, INITIAL_STATE as daily_state } from "./daily/daily";

export interface OutcomesState {
  daily: DailyOutcomesState;
}

export const INITIAL_STATE = {
  daily: daily_state,
};

export default combineReducers({ daily });
