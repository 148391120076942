import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  DailyOutcomesState,
  OutcomeNumber,
  editOutcome,
  saveOutcomes,
  setDoing,
} from "../../../store/outcomes/daily/daily";
import { InputField, InformationArea, Button } from "../../../components";

function AddOutcomesView() {
  const dispatch = useDispatch();
  const outcomes = useSelector<RootState, DailyOutcomesState>((state) => state.outcomes.daily);
  const showInputTwo = outcomes.first.text.length > 0 || outcomes.second.text.length > 0;
  const showInputThree = outcomes.second.text.length > 0 || outcomes.third.text.length > 0;
  const showButton =
    outcomes.first.text.length > 0 && outcomes.second.text.length > 0 && outcomes.third.text.length > 0;

  const handleChange = (number: OutcomeNumber) => {
    return (value: string) => {
      dispatch(editOutcome(number, value));
    };
  };

  const handleClick = () => {
    dispatch(setDoing(true));
    dispatch(saveOutcomes());
  };

  return (
    <div data-testid="add-outcomes-view">
      <InputField tag={1} value={outcomes.first.text} onChange={handleChange(OutcomeNumber.First)} />
      {showInputTwo && (
        <InputField tag={2} value={outcomes.second.text} onChange={handleChange(OutcomeNumber.Second)} />
      )}
      {showInputThree && (
        <InputField tag={3} value={outcomes.third.text} onChange={handleChange(OutcomeNumber.Third)} />
      )}
      {showButton && (
        <div className="flex justify-center">
          <Button text="Do It" onClick={handleClick} />
        </div>
      )}
      {!showInputThree && (
        <InformationArea text="Which three results do you want for the day? &#13;&#10; Try to have something personal, something for work and something for your relationships." />
      )}
    </div>
  );
}

export default AddOutcomesView;
