import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";

function Header() {
  return (
    <header className="flex flex-row justify-between items-center m-4">
      <div>
        <FontAwesomeIcon icon={faCheckSquare} size="2x" />
      </div>
      <h1>ToDoIt</h1>
    </header>
  );
}

export default Header;
