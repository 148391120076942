import React from "react";

export interface DoableItemProps {
  tag: number;
  text: string;
  done: boolean;
  onClick?: (done: boolean) => void;
}

function DoableItem(props: DoableItemProps) {
  const tagClassBase = "bg-orange-400 text-white text-center rounded-md p-1 w-10 mr-4 ";
  const tagClass = props.done ? tagClassBase + "bg-green-400" : tagClassBase;

  const textClassBase = "text-lg ";
  const textClass = props.done ? textClassBase + "line-through text-gray-400" : textClassBase;

  return (
    <div
      className="flex flex-row m-8 items-center cursor-pointer"
      data-testid="doable-item"
      onClick={() => props.onClick && props.onClick(!props.done)}
    >
      <span className={tagClass}>#{props.tag}</span>
      <span className={textClass}>{props.text}</span>
    </div>
  );
}

export default DoableItem;
