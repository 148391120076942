import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { setNewDate, saveOutcomes } from "../../../store/outcomes/daily/daily";
import { today } from "../DailyOutcomes";

function NewDayView() {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col items-center" data-testid="new-day-view">
      <span className="m-12 text-lg">There is nothing here, create your three daily outcomes.</span>
      <Button
        icon={faPlus}
        onClick={() => {
          dispatch(setNewDate(today()));
          dispatch(saveOutcomes());
        }}
      />
    </div>
  );
}

export default NewDayView;
