import React, { useRef } from "react";
import "./InputField.css";

export interface InputFieldProps {
  tag?: number;
  value?: string;
  onChange?: (value: string) => void;
  onEnterPressed?: (value: string) => void;
}

function InputField(props: InputFieldProps) {
  const inputField = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    props.onChange && props.onChange(inputField.current?.value || "");
  };

  return (
    <div className="flex flex-col m-4 ">
      <div className="bg-orange-400 w-8 text-center ml-4 rounded-t-md">#{props.tag}</div>
      <input
        className="outline-none bg-transparent border border-blue-400 focus:border-green-400 rounded-md text-lg p-4 placeholder-gray-600 transition duration-700"
        data-testid={`input-field-#${props.tag}`}
        ref={inputField}
        value={props.value}
        type="search"
        maxLength={50}
        placeholder="Define your outcome"
        onChange={handleChange}
        onBlur={handleChange}
        onKeyUp={(e) =>
          e.key === "Enter" && props.onEnterPressed && props.onEnterPressed(inputField.current?.value || "")
        }
      />
    </div>
  );
}

InputField.defaultProps = {
  tag: 1,
};

export default InputField;
