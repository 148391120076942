import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export interface InformationAreaProps {
  text: string;
}

function InformationArea(props: InformationAreaProps) {
  const [showText, setShowText] = useState(true);

  const areaClassBase =
    "bg-orange-400 text-center text-lg rounded-t-md w-full h-0 transform-height duration-500 ease-in-out";
  const areaClass = showText ? areaClassBase + " h-auto p-4 pt-8 pb-8" : areaClassBase;

  return (
    <div
      className="absolute bottom-0 flex flex-col p-4 pb-0 w-full cursor-pointer"
      data-testid="information-area"
      onClick={() => setShowText(!showText)}
    >
      <button className="outline-none bg-orange-400 w-12 h-10 text-center ml-4 rounded-t-md">
        <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
      </button>
      <div className={areaClass}>
        <span className={showText ? "block" : "hidden"}>{props.text}</span>
      </div>
    </div>
  );
}

export default InformationArea;
