import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ButtonProps {
  text?: string;
  icon?: IconProp;
  onClick?: () => void;
}

function Button(props: ButtonProps) {
  return (
    <button
      className="outline-none rounded-full bg-blue-400 focus:bg-green-400 transition duration-500 p-4 pl-6 pr-6 text-2xl uppercase"
      onClick={props.onClick}
    >
      {props.icon && <FontAwesomeIcon icon={props.icon} />}
      {props.icon && props.text && <span className="ml-4"></span>}
      {props.text}
    </button>
  );
}

export default Button;
