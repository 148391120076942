import React from "react";
import { Header } from "./components";
import { DailyOutcomes } from "./pages";

function App() {
  return (
    <div className="App">
      <Header />
      <DailyOutcomes />
    </div>
  );
}

export default App;
