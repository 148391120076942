import React from "react";
import { DoableItem, Button } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  DailyOutcomesState,
  OutcomeNumber,
  setDone,
  setDoing,
  saveOutcomes,
} from "../../../store/outcomes/daily/daily";
import { faPen } from "@fortawesome/free-solid-svg-icons";

function DoItView() {
  const dispatch = useDispatch();
  const outcomes = useSelector<RootState, DailyOutcomesState>((state) => state.outcomes.daily);

  const showCelebration = outcomes.first.done && outcomes.second.done && outcomes.third.done;

  const handleClick = (number: OutcomeNumber) => {
    return (value: boolean) => {
      dispatch(setDone(number, value));
      dispatch(saveOutcomes());
    };
  };

  return (
    <div data-testid="doit-view" className="DoItView">
      <DoableItem
        tag={1}
        text={outcomes.first.text}
        done={outcomes.first.done}
        onClick={handleClick(OutcomeNumber.First)}
      />
      <DoableItem
        tag={2}
        text={outcomes.second.text}
        done={outcomes.second.done}
        onClick={handleClick(OutcomeNumber.Second)}
      />
      <DoableItem
        tag={3}
        text={outcomes.third.text}
        done={outcomes.third.done}
        onClick={handleClick(OutcomeNumber.Third)}
      />
      <div className="flex justify-center m-12">
        {showCelebration ? (
          <div className="flex flex-col items-center text-3xl text-center">
            <span className="text-6xl" role="img" aria-label="Celebration">
              🎉
            </span>
            Congratulations, you did it!
          </div>
        ) : (
          <Button icon={faPen} onClick={() => dispatch(setDoing(false))} />
        )}
      </div>
    </div>
  );
}

export default DoItView;
